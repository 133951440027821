







































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";
import { Toast } from "vant";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class OrderLogistics extends Vue {
  public user_id = "";
  public order_mation_id = ""; // 子订单id
  public logistics: any[] = []; // 物流信息
  public logisticsList: any[] = []; // 物流信息
  public active: number = 0; // 物流信息

  // 请求物流信息
  getLogistics() {
    let _this = this;
    this.$api.request({
      url: "order/order-info/kd-log",
      data: { order_mation_id: this.order_mation_id },
      // data: { order_mation_id: 4651 },/
      success(res) {
        // if (res.data.State === "0") {
        //   Toast(res.data.Reason);
        // } else if (res.data.Traces) {
          _this.logisticsList = res.data;
        // }
      }
    });
  }
  // 刷新页面
  onRefresh() {
    this.logistics = [];
    this.getLogistics();
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.order_mation_id = String(this.$route.query.order_mation_id || "");
    this.logistics = [];
    this.getLogistics();
  }
}
